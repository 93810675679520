<template>
  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlStart-->

  <div id="defaultId1" class="detail_con" >

    <gui_card id="ge6845"  class="   uc1096" label="" :isShowLabel="false">
      <div>
     
        <svg t="1728612191932" @click="$router.go(-1)" ref="scrollContainers"  style="margin-bottom: 18px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9222" width="24" height="24"><path d="M879.476364 470.341818H244.829091L507.112727 209.454545a41.658182 41.658182 0 0 0-58.88-58.88L114.967273 482.676364a41.890909 41.890909 0 0 0 0 58.88l333.265454 333.032727a41.658182 41.658182 0 0 0 58.88 0 41.890909 41.890909 0 0 0 0-58.88L244.829091 553.658182h634.647273a41.658182 41.658182 0 1 0 0-83.316364z" p-id="9223" fill="#1296db"></path></svg>
        <!-- <svg t="1718768811694" @click="$router.go(-1)" class="icon" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" p-id="4349" width="40" height="40">
          <path
            d="M938.666667 533.333333a21.333333 21.333333 0 0 1-21.333334 21.333334H158.166667l134.253333 134.246666a21.333333 21.333333 0 1 1-30.173333 30.173334l-170.666667-170.666667a21.333333 21.333333 0 0 1 0-30.173333l170.666667-170.666667a21.333333 21.333333 0 0 1 30.173333 30.173333L158.166667 512H917.333333a21.333333 21.333333 0 0 1 21.333334 21.333333z"
            fill="#1290C9" p-id="4350"></path>
        </svg> -->
        <div class="importDetail-head flex_center_between_box" style="  align-items: center;">

          <div class="importDetail-item">
            <span class="importDetail-item-txt">Date:</span>
            <span class="importDetail-item-conter"> 
              <span class="font_bold">{{ value?.imp_year }}</span>
              <span class="font_bold" v-if="value?.imp_month">-</span>
              <span class="font_bold">{{ value?.imp_month }}</span>
              <span class="font_bold" v-if="value?.imp_day">-</span>
              <span class="font_bold">{{ value?.imp_day ? value?.imp_day : '' }}</span>
            </span>
          </div>
          <div class="importDetail-item">
            <span class="importDetail-item-txt">Master Bill of Lading:</span>
            <span class="importDetail-item-conter">{{ value?.master_bill_of_lading }}</span>
          </div>
          <div class="importDetail-item">
            <span class="importDetail-item-txt">Source:</span>
            <span class="importDetail-item-conter">
              <span class="font_bold">{{ value?.channel_no }}_</span>
              <span class="font_bold" v-if="value?.iesign == 'i'">IMPORT</span>
              <span class="font_bold" v-if="value?.iesign == 'e'">EXPORT</span>
            </span>
          </div>
          <div class="importDetail-item">
            <span class="importDetail-item-txt">Bill No:</span>
            <span class="importDetail-item-conter"> <span class="font_bold">{{ value?.source_id }}</span>
              <span v-if="value?.source_id_type" class="font_bold">({{ value?.source_id_type }})</span></span>
          </div>
        </div>
        <h2 class="operations-head">Importer/Exporter</h2>
        <div class="">
          <div class="importDetail-content-item flex_center_between_box">
            <div class="importDetail-content-head">Importer / Buyer</div>
            <div class="importDetail-content-head">Exporter / Seller</div>
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Name</div>
              <div class="importtext" @click="tradetodetail(value?.importer_aaaid, value?.importer_country)">{{
                value?.importer_name }}</div>
            </div>
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Name</div>
              <div class="importtext">{{ value?.supplier_name }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Address</div>
              <div class="importtexttwo">{{ value?.importer_address }}</div>
            </div>
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Address</div>
              <div class="importtexttwo">{{ value?.supplier_address }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Country</div>
              <div class="importtext">{{ getCountry(value?.importer_country) }}</div>
            </div>
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Country</div>
              <div class="importtext">{{ getCountry(value?.supplier_country) }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Contact</div>
              <div class="importtexttwo">{{ value?.importer_contact }}</div>
            </div>
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Contact</div>
              <div class="importtexttwo">{{ value?.supplier_contact }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Email</div>
              <div class="importtexttwo">{{ value?.importer_email }}</div>
            </div>
            <div class="importDetail-content-import">
              <div class="importDetail-item-txt importtitle">Email</div>
              <div class="importtexttwo">{{ value?.supplier_email }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>

        </div>
      </div>
    </gui_card>
    <gui_card id="ge6845" class="  uc1096" label="" :isShowLabel="false" style="margin-top:10px">
      <div>

        <h2 class="operations-head">Cargo Info</h2>
        <div class="importDetail-content ">

          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">HS Code</div>
              <div class="importtexttwo">{{ value?.hs_code }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Quantity</div>
              <div class="importtexttwo">{{ value?.quantity }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Weight Unit</div>
              <div class="importtexttwo">{{ value?.weight_unit }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Product Description</div>
              <div class="importtexttwo">
                <div v-if="!isExpanded" @click="toggleExpanded" v-html="limitedDescription"></div>
                <div v-else @click="toggleExpanded" v-html="value?.commodity_description"></div>
                <span style="float: right;color: #1290c9;cursor: pointer;text-decoration: underline;"
                  v-if="value?.commodity_description?.length > maxCharacters" @click="toggleExpanded">{{ isExpanded ?
                    'Retract' : 'Expand All Content' }}</span>
              </div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Amount</div>
              <div class="importtexttwo" v-if="value?.fob_usd != null">{{ value?.fob_usd }}</div>
              <div class="importtexttwo" v-else>{{ value?.other_currency }}{{ value?.fob_oc }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Quantity Unitt</div>
              <div class="importtexttwo">{{ value?.quantity_unit }}</div>
            </div>

          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Weight</div>
              <div class="importtexttwo">{{ value?.weight }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">HS Description</div>
              <div class="importtexttwo">{{ value?.hs_code_description }}
              </div>
              <div class="importDetail-content-second">
                <div class="importDetail-item-txt importtitle">Incoterms</div>
                <div class="importtexttwo" v-if="value?.value_type == 'CIF' || value?.value_type == 'FOB'">{{ value?.value_type }}</div>
                <div class="importtexttwo" v-else></div>
              </div>
              <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
            </div>


          </div>
        </div>
      </div>
    </gui_card>
    <gui_card id="ge6845" class="  uc1096" label="" :isShowLabel="false" style="margin-top:10px">
      <div>

        <h2 class="operations-head">Shipping Info</h2>
        <div class="importDetail-content ">

          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Vessel</div>
              <div class="importtexttwo">{{ value?.vessel }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Consignee</div>
              <div class="importtexttwo">{{ value?.importer_name }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Means of Transport</div>
              <div class="importtexttwo">{{ value?.trans_type }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Country of Origin</div>
              <div class="importtexttwo">{{ getCountry(value?.origin_country) }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Voyage</div>
              <div class="importtexttwo">{{ value?.voyage }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Notifier</div>
              <div class="importtexttwo">{{ value?.notify_party_name }}</div>
            </div>

          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Country of Destnation</div>
              <div class="importtexttwo">{{ value?.dest_country }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Address of Origin</div>
              <div class="importtexttwo">{{ value?.poe }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle"></div>
              <div class="importtexttwo"></div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Shipper</div>
              <div class="importtexttwo">{{ value?.supplier_name }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Address of Destnation</div>
              <div class="importtexttwo">{{ value?.poe }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle"></div>
              <div class="importtexttwo"></div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>



        </div>
      </div>
    </gui_card>
    <gui_card id="ge6845" class="  uc1096" label="" :isShowLabel="false" style="margin-top:10px">
      <div>

        <h2 class="operations-head">Container</h2>
        <div class="importDetail-content ">
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Container ID</div>
              <div class="importtexttwo">{{ value?.container_id }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Container Type</div>
              <div class="importtexttwo">{{ value?.container_type }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Container Desc Dode</div>
              <div class="importtexttwo">{{ value?.container_desc_code }}</div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
          <div class="flex_center_between_box importDetail-content-box">
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle">Container Size</div>
              <div class="importtexttwo">{{ value?.container_size }}</div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle"></div>
              <div class="importtexttwo"></div>
            </div>
            <div class="importDetail-content-second">
              <div class="importDetail-item-txt importtitle"></div>
              <div class="importtexttwo"></div>
            </div>
            <!-- <div class="importDetail-content-import">Exporter / Seller</div> -->
          </div>
        </div>
      </div>
    </gui_card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->

</template>
<script>
// interfaceCode
//importStart

import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import ReLogin from "../../../../components/ReLogin.vue";
//importEnd
import {
  getbusinessActivitiesDetails
} from "@/api/companyApi";
import { mixins1 } from '@/mixins/index';
export default {
  name: "",
  mixins: [mixins1],
  props: {
    // srcviewscompanydetailsBackgroundSnapshotvuePropsStart
    // srcviewscompanydetailsBackgroundSnapshotvuePropsEnd
  },
  mounted() {

    this.getbusinessActivitiesDetailsList()
    // defaultLoad
    // srcviewscompanydetailsBackgroundSnapshotvueMountedStart
    // srcviewscompanydetailsBackgroundSnapshotvueMountedEnd
  },

  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  computed: {
    limitedDescription() {
      const maxCharacters = 200;
      if (this.value && this.value?.commodity_description) {
        if (this.value.commodity_description.length > maxCharacters) {
          return this.value?.commodity_description.substring(0, maxCharacters) + '...';
        } else {
          return this.value?.commodity_description;
        }
      }
      return '';
    },

    rows() {
      const rows = [];
      let row = [];
      for (const item of this.content) {
        if (item.field) {
          row.push(item);
          if (row.length === 2) {
            rows.push(row);
            row = [];
          }
        } else {
          rows.push([item]);
        }
      }
      if (row.length > 0) {
        rows.push(row);
      }
      return rows;
    },
  },
  data() {
    return {
      reLoginVis: false,
      // insertData
      // srcviewscompanydetailsBackgroundSnapshotvueDataStart

      // LegalData
      LegalData: {
        color: "#009EA7",
        title: "Legal Status",

        data: [],

      },
      isExpanded: false,
      maxCharacters: 200,
      value: {},
      content: [],

      // FinancialData


      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },


  methods: {

    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    getbusinessActivitiesDetailsList() {
      getbusinessActivitiesDetails({
        id: this.$route.query.DetailsiD
      }).then(res => {
        if (res.code == 200) {
      
          this.value = res.data;

          this.value.container_size = this.value?.container_size == undefined ? '' : this.value?.container_size.split(',')[0];
          this.value.container_type = this.value?.container_type == undefined ? '' : this.value?.container_type.split(',')[0];
          this.value.container_desc_code = this.value?.container_desc_code == undefined ? '' : this.value?.container_desc_code.split(',')[0];
          const container = this.$refs.scrollContainers; // 使用 ref 获取容器
     
          this.$parent.$parent.scrollToTop()

    
      
        } else {
          
          this.value = {};
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    // insertMethod
    // srcviewscompanydetailsBackgroundSnapshotvueMethodStart
    // default
    default() { },
    // srcviewscompanydetailsBackgroundSnapshotvueMethodEnd
  },
};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;

}

.importDetail-item-txt {
  font-size: 1.4285714285714286rem;
  color: #8497ab;
  margin-right: 0.7142857142857143rem;
}

.importDetail-item-conter {
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
}

.importDetail-head {
  height: 4.285714285714286rem;
  background: #f5f5f5;
  border-radius: 0.2857142857142857rem;
  padding: 0 4.285714285714286rem;
  margin-bottom: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  color: #1a2332;
  text-align: left;
  line-height: 1.9285714285714286rem;
  font-family: 'DMSans Bold';
}

.importDetail-content-head {
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #ff7600;
  line-height: 1.6428571428571428rem;
  text-align: left;
  width: 50%;
  margin-top: 2.142857142857143rem;
  margin-bottom: 1.4285714285714286rem;

}

.importtitle {
  margin-bottom: 1rem;
}

.importtext {
  font-size: 1.4285714285714286rem;
  color: #1290C9;
  line-height: 2.142857142857143rem;

  text-decoration-line: underline;
}

.importDetail-content-import {
  width: 50%;
}

.importDetail-content-second {
  width: 30%;
}

.importDetail-content-box {
  padding-bottom: 2.142857142857143rem;
}

.importtexttwo {
  font-size: 1.4285714285714286rem;
  color: #1A2332;
  line-height: 2.142857142857143rem;
}

.importDetail-content {
  margin-top: 2.142857142857143rem;

}
.callBack{
  font-size: 1.4285714285714286rem;
color: #1290C9;
line-height: 1.6428571428571428rem;
display: block;
font-style: normal;
text-decoration-line: underline;
margin-bottom: 1.4285714285714286rem;
}

.uc1096 {
  padding: 2.142857142857143rem 2.857142857142857rem 0.35714285714285715rem;
}

.detail_con {
  padding-bottom: 1.4285714285714286rem;
}
</style>